$(document).ready(function() {
    $('select[account-switch]').on('change', function () {
        $.ajax({
            url: '/account/switch/' + $(this).val(),
            type: 'POST',
            dataType: 'json',
            success: function (data) {
                window.location.href = window.location.href;
            }
        });
    });
});