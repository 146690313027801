import { provide, inject } from 'vue';
// @ts-ignore
const matomo = window._paq;
export const trackEvent = (category, action, name = '', value = '') => {
    matomo.push(['trackEvent', category, action, name, value]);
};
/*
 * Select the location by priority like this :
 * 1) Location in props (arg location)
 * 2) Location already injected by parent (probably more precise than default location)
 * 3) Arg defaultLocation
 */
export const setLocation = (location, defaultLocation) => {
    if (false !== location) {
        provide('elLocation', location);
    }
    else if (undefined === inject('elLocation', undefined)) {
        provide('elLocation', defaultLocation);
    }
};
