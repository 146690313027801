import { ready, addEventListener } from '../../main';
import { trackEvent } from '../../matomo/matomo';
ready(() => {
    document.querySelectorAll('[menu-slide-trigger]').forEach((triggerEl) => {
        triggerEl.addEventListener('click', (event) => {
            triggerSlide(event);
        });
    });
    document.querySelectorAll('[menu-slide-container]').forEach((containerEl) => {
        addEventListener(containerEl, 'click', (el) => {
            history.back();
        }, '[menu-slide-background]');
    });
});
export function triggerSlide(event) {
    const el = event.currentTarget;
    const channel = el.getAttribute('menu-slide-trigger') ?? '';
    const location = el.dataset.location ?? '';
    if ('' !== location) {
        trackEvent('Menu', channel, location);
    }
    import('../module/slide').then(({ loadAndToggleMenuSlide }) => {
        loadAndToggleMenuSlide(channel);
    });
    switch (el.getAttribute('menu-slide-trigger-hide')) {
        case 'true':
            el.style.display = 'none';
            break;
    }
}
