export function setAutocompleteTriggers($item) {
    $item = $($item)

    $item
        .on('blur', '.autocomplete-input', function (e) {
            import('./autocomplete').then(({onBlur: onBlur}) => {
                onBlur(e);
            });
        })
        .on('focus', '.autocomplete-input', function (e) {
            import('./autocomplete').then(({focus: focus}) => {
                focus(e);
            });
        })
        .on('keydown', '.autocomplete-input', function (e) {
            import('./autocomplete').then(({listenKeyDown: listenKeyDown}) => {
                listenKeyDown(e);
            });
        })
        .on('click', '[autocomplete-click="selectOnClick"]', function (e) {
            import('./autocomplete').then(({selectOnClick: selectOnClick}) => {
                selectOnClick(e);
            });
        })
    ;
}

$(document).ready(function () {
    setAutocompleteTriggers($('form'));
});