// assets/js/app.js
const $ = require('jquery');

global.$ = global.jQuery = $;

require('../js/autocomplete/trigger');

require('../js/account/switch');

require('../js/datepicker/trigger/event_search');

require('../ts/menu-slide/trigger/slide');
require('../js/price/pricecat');

require('../scss/app/_include.scss');
$(document).ready(function () {
    $('link[rel="stylesheet-defer"]').attr('rel', 'stylesheet');
});

import('../ts/fontawesome/fontawesome');
import('lazysizes');
import('../vue/load-vue')
import('../vue/load-vue-form')
import('../ts/form/form')
import('../js/show/show')
import('../ts/lazyload/footer/footer')
import('../ts/pwa/pwa')
import('../ts/menu-shrink/trigger/shrink')
import('../ts/history/rewrite')
import('../ts/map/load-map')
import('../ts/cta/cta')
import('../ts/matomo/matomo')
