// Spécial trigger for eventSearch

import {ready} from '../../../ts/main'

export function setDatepickerTriggers(form) {
    const shortList = form.querySelector('[data-search-event="shortlist"]')

    if (undefined !== shortList) {
        shortList.addEventListener('focus', () => {
            import('../module/event_search').then(({setDatepickerEventSearch}) => {
                setDatepickerEventSearch(form);
            });
        })

        // For IOs : focus is too late, shortlist is already generated and cannot be changed on the fly
        shortList.addEventListener('touchstart', () => {
            import('../module/event_search').then(({setDatepickerEventSearch}) => {
                setDatepickerEventSearch(form);
            });
        })
    }

    const datepickerButton = form.querySelector('[data-search-event="trigger-datepicker"]')

    if (undefined !== datepickerButton) {
        datepickerButton.addEventListener('click', () => {
            import('../module/event_search').then(({setDatepickerEventSearch}) => {
                setDatepickerEventSearch(form)
                    .then((datepicker) => {
                        datepicker.datepicker('show');
                    });
            });
        })
    }

    // If shortlist is set on date at loading, we need to load the date
    if (form.querySelector('[data-search-event="shortlist"]').value == date_between_search_date) {
        import('../module/event_search').then(({setDatepickerEventSearch}) => {
            setDatepickerEventSearch(form);
        });
    }
}

ready(() => {
    document.querySelectorAll('[data-search-event="form"]').forEach((form) => {
        setDatepickerTriggers(form);
    })
})
